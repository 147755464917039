const $merge = require('lodash.merge')

const config = require('./config.base')
const loadShell = require('./utils/voicerconfig/load-shell')

const configEnvironments = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  next: require('./config.next'),
}

let configLocal = require('./utils/voicerconfig/load-local')
let envConfig = null

if (process.env.ADD_CONFIG_OPTIONS) {
  try {
    console.log(`🤓 use environment options`)
    envConfig = JSON.parse(process.env.ADD_CONFIG_OPTIONS)
  } catch (error) {
    console.error(`Invalid environment options`)
    console.error(`You passed`, process.env.ADD_CONFIG_OPTIONS)
    console.error(error)
    process.exit(1)
  }
}

const transformConfig = (config) => {
  if (config.useShell) {
    config = loadShell(config)
  }
  return config
}

/**
 * default configuration file
 * common no matter the BROCOLI_API_MODE
 * you can override properties in
 * config.[BROCOLI_API_MODE].js configurations files
 */
const endConfig = $merge(
  {},
  config,
  {
    /**
     * common configuration here
     */
    siteId: '5e8f1e1e161766069ca55f46',
    authMode: [
      'local',
      {
        mode: 'default',
      },
    ],   
    enableSplashScreen: 'wavy',
    showCardDescription: true,
    metadatas: {
      name: 'Nom du projet',
      colorPrimary: '#70b959',
      colorAccent: '#69605d',
    },
    analytics: {
      ga: 'UA-111736601-49',
      mixpanel: 'b15c0f95712d58113bd31dec4d898d9b',
      matomo: '4',
      sentry:
        'https://2b2ac6306f2e484cb688c931afbee1a6@o307478.ingest.sentry.io/5455736',
    },
    useShell: [
      'bb-default',
      {
        mode: 'universes',
        universes: [
          {
            name: 'collection:premiere',
            type: 'tag',
            tagId: '6036c4ee3052ac63142f0ff5',
            i18n: {
              fr: {
                title: 'Mes podcasts',
                description: 'Mes podcasts',
              },
            },
            heading: {
              canRadio: false,
              color: '#107474',
              dark: true,
              hideTitle: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/cover-univers1.png')
                  : require('~/assets/images/cover-univers1.png')
              },
              thumbnailImage() {
                return require('~/assets/images/univers1.png')
              },
            },
          },
          {
            name: 'collection:seconde',
            type: 'tag',
            tagId: '6036c4f84c1a18b73749b0e4',
            i18n: {
              fr: {
                title: 'Mes émissions',
                description: 'Mes émissions',
              },
            },
            heading: {
              canRadio: false,
              color: '#e1c208',
              dark: true,
              hideTitle: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/cover-univers1.png')
                  : require('~/assets/images/cover-univers1.png')
              },
              thumbnailImage() {
                return require('~/assets/images/univers2.png')
              },
            },
          },
          {
            name: 'collection:troisième',
            type: 'tag',
            tagId: '6036c4f84c1a18b73749b0e4',
            i18n: {
              fr: {
                title: 'Mes chroniques',
                description: 'Mes chroniques',
              },
            },
            heading: {
              canRadio: false,
              color: '#1aadb5',
              dark: true,
              hideTitle: true,
              backgroundImage(context) {
                return context.$voicer.isMobile
                  ? require('~/assets/images/cover-univers1.png')
                  : require('~/assets/images/cover-univers1.png')
              },
              thumbnailImage() {
                return require('~/assets/images/univers2.png')
              },
            },
          },
        ],
      },
    ],
  },
  configEnvironments[process.env.BROCOLI_API_MODE],
  configLocal,
  envConfig
)

module.exports = transformConfig(endConfig)
